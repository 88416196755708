/************************************************
* HEADER Standard
*************************************************/
    
    .header {
        position: relative;

        img {
            display: block;
        }

        .headertext {
            /*position: absolute;
            bottom: 0;
            right: 0;
            left: 0;*/
            background-color: white;
           
            padding: 3.5rem 0;

            .text {
                width: 70%;
            }

            h1,
            h2 {
                margin: 0 0 .5rem 0;
            }

            p {
                font-size: 2rem;
                color: $fontColor;
            }
        }

        /* Videoheader */

        &.video picture {
            position: relative;
            display: block;

            .buttons {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            .button {
                margin: 0;
            }
        }
    }


/************************************************
* RESPONSIVE
*************************************************/

    @media(max-width: 1400px) {
        .header .headertext {
            padding: 2.5rem 0;
        } 
    }

    @media (max-width: 1300px) {
        .header .headertext {
            position: relative;
            padding: 2.5rem;

            .wrap {
                width: 100%;
            }
        }
    }

    @media (max-width: 900px) {
        .header .headertext .text {
            width: 100%;
        }
    }

    @media(max-width: 800px) {
        .header .headertext {
            padding: 2rem;
        }
    }

    @media (max-width: 600px) {
        .header .headertext {
            padding-bottom: 2rem;

            .text span {
                font-size: 1.8rem;
            }
        }
    }