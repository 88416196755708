/************************************************
* natural box-sizing
*************************************************/

	*, *:after, *:before{
		box-sizing: border-box;
	}

/************************************************
* responsive images
*************************************************/

	img {
		max-width: 100%;
		height: auto;
	}

/************************************************
* general CSS Stylings
*************************************************/

	body {
		margin: 0;
		background-color: #0d0d0d;
	}

	#pageWrapper {
		max-width: 1920px;
		margin-left: auto;
		margin-right: auto;
	}

	.wrap {
		max-width: 1350px;
		margin-left: auto;
		margin-right: auto;
	}

	.noBottomPadding {
		padding-bottom: 0 !important;
	}

/************************************************
* RESPONSIVE
*************************************************/

	@media (max-width: 1400px) {
		.wrap {
			width: 95%;
			margin-left: auto;
			margin-right: auto;
		}
	}

	@media (max-width: 600px) {
		.wrap {
			width: 92%;
		}
	}