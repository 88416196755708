/************************************************
* navLang
*************************************************/

#language {
    display: none;

    &.show {
        /* only on mobile */
        @media all and (max-width: 449px) {
            display: block;
            position: absolute;
            text-align: right;
            z-index: 1;
            top: 50px;
            padding: .5rem 1rem;
            width: 100%;
            background-color: $brandFirst;
        }
    }

    a {
        display: inline-block;
        font-size: 1.4rem;
        color: white;
        text-transform: uppercase;
        padding: .5rem 0;
        text-decoration: none;
        
        @media(min-width: 1025px) {
            &:hover {
                color: white;
                text-decoration: none;
                transition: color .2s ease-in;
            }
        }
    }

    span:before {
        color: #beb4b2;
        font-size: 1.4rem;
        text-transform: uppercase;
    }
    span:after {
        content: '|';
        color: #beb4b2;
        font-size: 1.4rem;
        padding: 0 .7rem 0 .2rem;
        display: inline-block;
        
    }
    span:last-child:after {
        content: none;
    }

    span.de a:before {
        content: 'de';
    }
    span.en a:before {
        content: 'en';
    }
    span.en.inactive:before {
        opacity: .5;
    }
    span.current a {
        opacity: .8;
    }
    span a:hover {
        text-decoration: none;
    }
}

.lang-button {
    padding: 1rem;
    float: right;

    &::after {
        @extend %icons;
        font-size: 2rem;
        content: '\e80d';
        color: white;
    }
}

/************************************************
* RESPONSIVE
*************************************************/

@media all and (min-width: 450px) {
    .lang-button {
        clear: none;
        display: none;
    }

    #language {
        display: block;
        float: right;
        padding-top: .5rem;
    }
}

@media all and (min-width: 650px) {
    #language {
        padding-top: .6rem;
    }
}

@media all and (min-width: 1200px) {
    #language {
        padding-right: .3rem;
    }
}