/************************************************
* General CONTENT Stylings
*************************************************/

    body > div > main > div {

      ul {
        padding-left: 0;
        margin: 0 0 1.8rem 0;
      }

      li {
        font-size: 1.8rem;
        list-style-type: none;
        padding-left: 3rem;
        position: relative;
      }

      li::before {
        @extend %icons;
        content: '\f178';
        position: absolute;
        color: $brandFirst;
        left: .2rem;
        top: .4rem;
        margin-bottom: 1rem;
      }

      li.phone::before {
        @extend %icons;
        content: '\e801';
        margin: 0;
      }

      li.fax::before {
        @extend %icons;
        content: '\e802';
        margin: 0;
      }

      li.mail::before {
        @extend %icons;
        content: '\e800';
        margin: 0;
      }

      li.address::before {
        @extend %icons;
        content: '\e808';
        margin: 0;
      }
    }

    body > div > main > div {
        a {
            color: $brandFirst;
            font-size: 1.8rem;
            text-decoration: none;
        }
    }


/************************************************
* RESPONSIVE
*************************************************/

    @media (max-width: 1024px) {
        body > div > div a:hover {
            text-decoration: none;
        }
    }