
/************************************************
* FONT
*************************************************/
  
  //@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700');

  /* open-sans-regular - latin */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: url('/fileadmin/agoform/fonts/open-sans-v15-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Open Sans Regular'), local('OpenSans-Regular'),
         url('/fileadmin/agoform/fonts/open-sans-v15-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/fileadmin/agoform/fonts/open-sans-v15-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('/fileadmin/agoform/fonts/open-sans-v15-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('/fileadmin/agoform/fonts/open-sans-v15-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/fileadmin/agoform/fonts/open-sans-v15-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
  }
  /* open-sans-700 - latin */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    src: url('/fileadmin/agoform/fonts/open-sans-v15-latin-700.eot'); /* IE9 Compat Modes */
    src: local('Open Sans Bold'), local('OpenSans-Bold'),
         url('/fileadmin/agoform/fonts/open-sans-v15-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/fileadmin/agoform/fonts/open-sans-v15-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('/fileadmin/agoform/fonts/open-sans-v15-latin-700.woff') format('woff'), /* Modern Browsers */
         url('/fileadmin/agoform/fonts/open-sans-v15-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/fileadmin/agoform/fonts/open-sans-v15-latin-700.svg#OpenSans') format('svg'); /* Legacy iOS */
  }

/************************************************
* Base Font Setting
*************************************************/

  html{
    font-size: 62.5%; // 1 rem = 10px
  }

  body{
    font-family: 'Open Sans', sans-serif;
  }

/************************************************
 * TYPO
 ************************************************/

  h1 {
    font-size: 3.4rem;
    line-height: 1.5;
    color: $brandFirst;
    margin-bottom: 0;
  }
  
  h2 {
    font-size: 2.8rem;
    line-height: 1.5;
    color: $fontColor;
  }

  h3 {
    font-size: 1.8rem;
    line-height: 1.5;
  }

  p {
    font-size: 1.8rem;
    line-height: 1.5;
    color: $fontColor;
  }

  main ul {
    color: $fontColor;
    line-height: 1.5;
  }

  a {

  
    &:hover {

    }

  }


/************************************************
 * RESPONSIVE
 ************************************************/

  @media (max-width: 1200px) {
    h1 {
      font-size: 2.8rem;
    }

    h2 {
      font-size: 2.4rem;
    }
  }

  @media (max-width: 1100px) {
    h2 {
      font-size: 2.2rem;
    }
  }

  @media (max-width: 600px) {
    h1 {
      font-size: 2.6rem;
    }
  }

  @media (max-width: 400px) {
    h1 {
      font-size: 2.2rem;
    }

    h2 {
      font-size: 2rem;
    }
  }

  