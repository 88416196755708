/************************************************
* TEXTBOX
*************************************************/
    
    .textBox {
        display: block;
        overflow: auto;
        padding: 6rem 0;
        position: relative;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-color: $brandFourth;

        &.background::after {
            content: '';
            background-color: rgba($brandFourth, .9);
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
        }

        /* column settings */

        &.oneCol {
            > h1,
            > h2,
            > h3,
            .column {
                width: 60%;
            }
        }

        &.threeCol .column {
            width: 30%;
        }

        h1 {
            margin: 0 0 2rem 0;
            position: relative;
            z-index: 20;
        }

        h2 {
            margin: 0 0 1rem 0;
            position: relative;
            z-index: 20;
        }

        .columns {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            position: relative;
            z-index: 20;
        }

        .column{

            h2, h3 {
                color: $brandFirst;
                margin: 2rem 0 1rem 0;
            }

            p {
                margin-top: 1.5rem;
            }

            img {
                margin-top: 2rem;
            }

        }

        /* textBox with icons (häkchen) */
        
        &.threeCol.icons{

            .column {
                padding-left: 3rem;
            }
            
            .column h2,
            .column h3 {
                position: relative;
            }

            .column h2::before,
            .column h3::before {
                @extend %icons;
                content: '\e805';
                position: absolute;
            }

            .column h2::before {
                left: -3.5rem;
                top: .2rem;
                font-size: 2.7rem;
            }

            .column h3::before {
                left: -3rem;
                font-size: 1.9rem;
            }

        }

        /* only on newsdetail */

        time {
            display: block;
            font-size: 1.8rem;
            font-weight: 700;
            color: $fontColor;
        }

        .linkText {
            display: block;
            margin-top: 5rem;
        }

    }

/************************************************
* RESPONSIVE
*************************************************/

    @media (max-width: 1200px) {
        .textBox {
            padding: 4rem 0;
        }
    }

    @media (max-width: 1100px) {
        .textBox.oneCol {
            > h1,
            > h2,
            > h3,
            .column {
                width: 80%;
            }
        }

    }

    @media (max-width: 900px) {
        .textBox.threeCol .column {
            width: 48%;
        }

        .textBox.oneCol{
            > h1,
            > h2,
            > h3,
            .column {
                width: 100%;
            }
        }
    }

    @media (max-width: 650px) {
        .textBox{
            &.threeCol .column {
                width: 100%;
            }

            .column h3 {
                margin-top: 1rem;
            }
        }
    }

    @media (max-width: 600px) {
        .textBox {
            padding: 2rem 0;
        }
    }