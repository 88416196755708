/************************************************
* navMain
*************************************************/

nav#navMain {
    clear: right;
    @include clearfix;
}

/* generelle Stylings für alle Ebenen */

ul.flexnav {
    z-index: 30;
    &.flexnav-show {
        padding-top: 3rem;
    }

	li {
		a {
			font-size: 1.6rem;
			color: white;
            text-transform: uppercase;
		}
	}
}

ul.level1 {
    > li {
        > a {
			padding: .7rem 1rem .7rem 1rem;
        }

        #nav12{
            &:before{
                @extend %icons;
            }
        }
    }
}

ul.level2 {
     > li {
        > a {
        	padding: .7rem 1rem .7rem 2rem;
        	background-color: #949392;
            color: white;
        }

        &.currentPage > a {
            color: $brandFirst;
        }
    }
}

ul.level3 {
    > li {
        > a {
        	padding: .5rem 1rem .5rem 3rem;
        	background-color: white;
        }
    }
}

.menu-button {
    padding: 1rem;
    float: right;

    &::after {
        @extend %icons;
        font-size: 2rem;
        content: '\f0c9';
        color: white;
    }
}

/************************************************
* RESPONSIVE
*************************************************/

@media all and (min-width: 500px) {
    ul.flexnav {
        &.flexnav-show {
            padding-top: 10%;
        }
    }
}

@media all and (min-width: 650px) {
    ul.flexnav {
        &.flexnav-show {
            padding-top: 6rem;
        }
    }
}

/* desktop nav */
@media all and (min-width: 1200px) {

    nav#navMain {
        float: right;
    }

    ul.flexnav {
        li {
            a {
                font-size: 1.4rem;
            }
        }
    }

    ul.level1 {
        > li {
            > a {
				padding: .5rem .7rem;
            }

            /* home icon*/
            #nav12{
                &:before{
                    content: '\e803';
                }
                span {
                    display: none;
                }
            }
        }
    }

    ul.level2 {
        padding-top: 1.5rem !important;
         > li {

            > a {
            	padding: .8rem 1rem .8rem 1rem;
                background-color: #949392;
                border-left: 1px solid $brandFirst;
                border-right: 1px solid $brandFirst;
                border-bottom: 2px solid #aaaaab;
                color: white;
                transition: background-color 0.5s ease;
                &:hover {
                    background-color: $brandFirst;
                    color: white;
                }
            }

            &:last-child > a {
                border-bottom: 0;
            }

            &.currentPage > a {
                background-color: $brandFirst;
                color: white;
            }
        }
    }

    ul.level3 {
        > li {
            > a {
            	padding: .5rem 1rem .5rem 1rem;
            }
        }
    }
}

@media all and (min-width: 1300px) {
    ul.level1 {
        > li {
            > a {
                padding: .5rem 1rem .5rem 1rem;
            }

            &:last-of-type {
                > ul.level2{
                    left: auto;
                    right: 0;
                }
            }
        }
    }
}

@media all and (min-width: 1550px) {
    ul.level1 > li:last-of-type > ul.level2{
        left: 0;
        right: auto;
    }
}