/************************************************
* General CONTENT Stylings
*************************************************/
	header {
		background-color: $brandFirst;
		position: relative;
		padding-top: 2.5%;
		padding-bottom: .5%;

		.wrap {
			@include clearfix;
		}

		@media (max-width: 500px) {
			.wrap {
				width: 100%;
			}
		}

		.logo {
			display: block;
			width: 22rem;
			width: 70%;
			position: absolute;
			top: 20px;
			left: 0;
			z-index: 10;
            border: 0;
		}
	}
	
/************************************************
* RESPONSIVE
*************************************************/	

@media (min-width: 400px) {
    header {
    	.logo {
    		width: 28rem;
    	}
    }
}

@media (min-width: 500px) {
    header {
    	padding-bottom: 2%;

    	.logo {
    		width: 60%;
    		top: 22px;
    	}
    }
}

@media (min-width: 650px) {
    header {
    	padding-top: 2rem;
    	padding-bottom: 1.5rem;

    	.logo {
    		width: 39rem;
    	}
    }
}

@media (min-width: 1200px) {
    header {
    	padding-top: .5rem;

    	.logo {
    		top: 36px;
    	}
    }
}