@charset "UTF-8";
/************************************************
* SETUP
*************************************************/
/* clearfix */
/* Breakpoints */
/************************************************
* Base Definitions
*************************************************/
/*****************
* Colors
******************/
/************************************************
* FONT
*************************************************/
/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url("/fileadmin/agoform/fonts/open-sans-v15-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Open Sans Regular"), local("OpenSans-Regular"), url("/fileadmin/agoform/fonts/open-sans-v15-latin-regular.eot?#iefix") format("embedded-opentype"), url("/fileadmin/agoform/fonts/open-sans-v15-latin-regular.woff2") format("woff2"), url("/fileadmin/agoform/fonts/open-sans-v15-latin-regular.woff") format("woff"), url("/fileadmin/agoform/fonts/open-sans-v15-latin-regular.ttf") format("truetype"), url("/fileadmin/agoform/fonts/open-sans-v15-latin-regular.svg#OpenSans") format("svg");
  /* Legacy iOS */ }

/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url("/fileadmin/agoform/fonts/open-sans-v15-latin-700.eot");
  /* IE9 Compat Modes */
  src: local("Open Sans Bold"), local("OpenSans-Bold"), url("/fileadmin/agoform/fonts/open-sans-v15-latin-700.eot?#iefix") format("embedded-opentype"), url("/fileadmin/agoform/fonts/open-sans-v15-latin-700.woff2") format("woff2"), url("/fileadmin/agoform/fonts/open-sans-v15-latin-700.woff") format("woff"), url("/fileadmin/agoform/fonts/open-sans-v15-latin-700.ttf") format("truetype"), url("/fileadmin/agoform/fonts/open-sans-v15-latin-700.svg#OpenSans") format("svg");
  /* Legacy iOS */ }

/************************************************
* Base Font Setting
*************************************************/
html {
  font-size: 62.5%; }

body {
  font-family: 'Open Sans', sans-serif; }

/************************************************
 * TYPO
 ************************************************/
h1 {
  font-size: 3.4rem;
  line-height: 1.5;
  color: #dd1617;
  margin-bottom: 0; }

h2 {
  font-size: 2.8rem;
  line-height: 1.5;
  color: #444343; }

h3 {
  font-size: 1.8rem;
  line-height: 1.5; }

p {
  font-size: 1.8rem;
  line-height: 1.5;
  color: #444343; }

main ul {
  color: #444343;
  line-height: 1.5; }

/************************************************
 * RESPONSIVE
 ************************************************/
@media (max-width: 1200px) {
  h1 {
    font-size: 2.8rem; }
  h2 {
    font-size: 2.4rem; } }

@media (max-width: 1100px) {
  h2 {
    font-size: 2.2rem; } }

@media (max-width: 600px) {
  h1 {
    font-size: 2.6rem; } }

@media (max-width: 400px) {
  h1 {
    font-size: 2.2rem; }
  h2 {
    font-size: 2rem; } }

/************************************************
* ICONS (fontello.com)
*************************************************/
@font-face {
  font-family: 'agoform-icons';
  src: url("/fileadmin/agoform/fonts/agoform-icons.eot?77128659");
  src: url("/fileadmin/agoform/fonts/agoform-icons.eot?77128659#iefix") format("embedded-opentype"), url("/fileadmin/agoform/fonts/agoform-icons.woff2?77128659") format("woff2"), url("/fileadmin/agoform/fonts/agoform-icons.woff?77128659") format("woff"), url("/fileadmin/agoform/fonts/agoform-icons.ttf?77128659") format("truetype"), url("/fileadmin/agoform/fonts/agoform-icons.svg?77128659#agoform-icons") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"]:before,
[class*=" icon-"]:before, ul.level1 > li #nav12:before, .menu-button::after, .lang-button::after, body > div > main > div li::before, body > div > main > div li.phone::before, body > div > main > div li.fax::before, body > div > main > div li.mail::before, body > div > main > div li.address::before, div.button::after,
a.button::after, .buttons a.videoButton::after, .textBox.threeCol.icons .column h2::before,
.textBox.threeCol.icons .column h3::before {
  font-family: "agoform-icons";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none; }

.icon-mail:before {
  content: '\e800'; }

/* '' */
.icon-phone:before {
  content: '\e801'; }

/* '' */
.icon-print:before {
  content: '\e802'; }

/* '' */
.icon-home:before {
  content: '\e803'; }

/* '' */
.icon-search:before {
  content: '\e804'; }

/* '' */
.icon-ok:before {
  content: '\e805'; }

/* '' */
.icon-download:before {
  content: '\e806'; }

/* '' */
.icon-pencil:before {
  content: '\e807'; }

/* '' */
.icon-location:before {
  content: '\e808'; }

/* '' */
.icon-down-open-big:before {
  content: '\e809'; }

/* '' */
.icon-left-open-big:before {
  content: '\e80a'; }

/* '' */
.icon-right-open-big:before {
  content: '\e80b'; }

/* '' */
.icon-up-open-big:before {
  content: '\e80c'; }

/* '' */
.icon-globe:before {
  content: '\e80d'; }

/* '' */
.icon-menu:before {
  content: '\f0c9'; }

/* '' */
.icon-angle-left:before {
  content: '\f104'; }

/* '' */
.icon-angle-right:before {
  content: '\f105'; }

/* '' */
.icon-angle-up:before {
  content: '\f106'; }

/* '' */
.icon-angle-down:before {
  content: '\f107'; }

/* '' */
.icon-export-alt:before {
  content: '\f14d'; }

/* '' */
.icon-doc-inv:before {
  content: '\f15b'; }

/* '' */
.icon-youtube-squared:before {
  content: '\f166'; }

/* '' */
.icon-xing-squared:before {
  content: '\f169'; }

/* '' */
.icon-youtube-play:before {
  content: '\f16a'; }

/* '' */
.icon-right:before {
  content: '\f178'; }

/* '' */
.icon-facebook-squared:before {
  content: '\f308'; }

/* '' */
/************************************************
* natural box-sizing
*************************************************/
*, *:after, *:before {
  box-sizing: border-box; }

/************************************************
* responsive images
*************************************************/
img {
  max-width: 100%;
  height: auto; }

/************************************************
* general CSS Stylings
*************************************************/
body {
  margin: 0;
  background-color: #0d0d0d; }

#pageWrapper {
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto; }

.wrap {
  max-width: 1350px;
  margin-left: auto;
  margin-right: auto; }

.noBottomPadding {
  padding-bottom: 0 !important; }

/************************************************
* RESPONSIVE
*************************************************/
@media (max-width: 1400px) {
  .wrap {
    width: 95%;
    margin-left: auto;
    margin-right: auto; } }

@media (max-width: 600px) {
  .wrap {
    width: 92%; } }

/************************************************
* Library's (optional)
*************************************************/
/**********************************
* Flexnav Base Stylings
**********************************/
ul.flexnav {
  list-style-type: none;
  margin: 0 auto;
  padding: 0;
  transition: none;
  -webkit-transform-style: preserve-3d;
  max-height: 0;
  overflow: hidden;
  /* generelle Stylings für alle Ebenen */ }
  ul.flexnav.opacity {
    opacity: 0; }
  ul.flexnav.flexnav-show {
    max-height: 2000px;
    opacity: 1;
    transition: all .5s ease-in-out; }
  ul.flexnav ul {
    list-style-type: none;
    margin: 0;
    padding: 0; }
  ul.flexnav li a {
    display: block;
    text-decoration: none; }

/************************************************
* RESPONSIVE
*************************************************/
@media all and (min-width: 1200px) {
  ul.flexnav {
    position: relative;
    overflow: visible;
    max-height: none;
    z-index: 2; }
    ul.flexnav:after {
      content: "";
      display: table;
      clear: both; }
    ul.flexnav.opacity {
      opacity: 1; }
  /* Stylings erste Ebene (nicht vererbt) */
  ul.flexnav > li {
    position: relative;
    float: left;
    overflow: visible; }
  /* Stylings zweite Ebene (nicht vererbt) */
  ul.level2 {
    position: absolute;
    top: auto;
    left: 0;
    display: none; }
    ul.level2 > li {
      position: relative; }
      ul.level2 > li > a {
        white-space: nowrap; }
  /* Stylings dritte Ebene (nicht vererbt) */
  ul.level3 {
    position: absolute;
    top: 0;
    left: 100%;
    display: none; }
    ul.level3 > li {
      position: relative; }
      ul.level3 > li a {
        white-space: nowrap; }
  .menu-button {
    display: none; } }

/************************************************
* HEADER
*************************************************/
/************************************************
* General CONTENT Stylings
*************************************************/
header {
  background-color: #dd1617;
  position: relative;
  padding-top: 2.5%;
  padding-bottom: .5%; }
  header .wrap:after {
    content: "";
    display: table;
    clear: both; }
  @media (max-width: 500px) {
    header .wrap {
      width: 100%; } }
  header .logo {
    display: block;
    width: 22rem;
    width: 70%;
    position: absolute;
    top: 20px;
    left: 0;
    z-index: 10;
    border: 0; }

/************************************************
* RESPONSIVE
*************************************************/
@media (min-width: 400px) {
  header .logo {
    width: 28rem; } }

@media (min-width: 500px) {
  header {
    padding-bottom: 2%; }
    header .logo {
      width: 60%;
      top: 22px; } }

@media (min-width: 650px) {
  header {
    padding-top: 2rem;
    padding-bottom: 1.5rem; }
    header .logo {
      width: 39rem; } }

@media (min-width: 1200px) {
  header {
    padding-top: .5rem; }
    header .logo {
      top: 36px; } }

/************************************************
* navMain
*************************************************/
nav#navMain {
  clear: right; }
  nav#navMain:after {
    content: "";
    display: table;
    clear: both; }

/* generelle Stylings für alle Ebenen */
ul.flexnav {
  z-index: 30; }
  ul.flexnav.flexnav-show {
    padding-top: 3rem; }
  ul.flexnav li a {
    font-size: 1.6rem;
    color: white;
    text-transform: uppercase; }

ul.level1 > li > a {
  padding: .7rem 1rem .7rem 1rem; }

ul.level2 > li > a {
  padding: .7rem 1rem .7rem 2rem;
  background-color: #949392;
  color: white; }

ul.level2 > li.currentPage > a {
  color: #dd1617; }

ul.level3 > li > a {
  padding: .5rem 1rem .5rem 3rem;
  background-color: white; }

.menu-button {
  padding: 1rem;
  float: right; }
  .menu-button::after {
    font-size: 2rem;
    content: '\f0c9';
    color: white; }

/************************************************
* RESPONSIVE
*************************************************/
@media all and (min-width: 500px) {
  ul.flexnav.flexnav-show {
    padding-top: 10%; } }

@media all and (min-width: 650px) {
  ul.flexnav.flexnav-show {
    padding-top: 6rem; } }

/* desktop nav */
@media all and (min-width: 1200px) {
  nav#navMain {
    float: right; }
  ul.flexnav li a {
    font-size: 1.4rem; }
  ul.level1 > li {
    /* home icon*/ }
    ul.level1 > li > a {
      padding: .5rem .7rem; }
    ul.level1 > li #nav12:before {
      content: '\e803'; }
    ul.level1 > li #nav12 span {
      display: none; }
  ul.level2 {
    padding-top: 1.5rem !important; }
    ul.level2 > li > a {
      padding: .8rem 1rem .8rem 1rem;
      background-color: #949392;
      border-left: 1px solid #dd1617;
      border-right: 1px solid #dd1617;
      border-bottom: 2px solid #aaaaab;
      color: white;
      transition: background-color 0.5s ease; }
      ul.level2 > li > a:hover {
        background-color: #dd1617;
        color: white; }
    ul.level2 > li:last-child > a {
      border-bottom: 0; }
    ul.level2 > li.currentPage > a {
      background-color: #dd1617;
      color: white; }
  ul.level3 > li > a {
    padding: .5rem 1rem .5rem 1rem; } }

@media all and (min-width: 1300px) {
  ul.level1 > li > a {
    padding: .5rem 1rem .5rem 1rem; }
  ul.level1 > li:last-of-type > ul.level2 {
    left: auto;
    right: 0; } }

@media all and (min-width: 1550px) {
  ul.level1 > li:last-of-type > ul.level2 {
    left: 0;
    right: auto; } }

/************************************************
* navLang
*************************************************/
#language {
  display: none; }
  #language.show {
    /* only on mobile */ }
    @media all and (max-width: 449px) {
      #language.show {
        display: block;
        position: absolute;
        text-align: right;
        z-index: 1;
        top: 50px;
        padding: .5rem 1rem;
        width: 100%;
        background-color: #dd1617; } }
  #language a {
    display: inline-block;
    font-size: 1.4rem;
    color: white;
    text-transform: uppercase;
    padding: .5rem 0;
    text-decoration: none; }
    @media (min-width: 1025px) {
      #language a:hover {
        color: white;
        text-decoration: none;
        transition: color .2s ease-in; } }
  #language span:before {
    color: #beb4b2;
    font-size: 1.4rem;
    text-transform: uppercase; }
  #language span:after {
    content: '|';
    color: #beb4b2;
    font-size: 1.4rem;
    padding: 0 .7rem 0 .2rem;
    display: inline-block; }
  #language span:last-child:after {
    content: none; }
  #language span.de a:before {
    content: 'de'; }
  #language span.en a:before {
    content: 'en'; }
  #language span.en.inactive:before {
    opacity: .5; }
  #language span.current a {
    opacity: .8; }
  #language span a:hover {
    text-decoration: none; }

.lang-button {
  padding: 1rem;
  float: right; }
  .lang-button::after {
    font-size: 2rem;
    content: '\e80d';
    color: white; }

/************************************************
* RESPONSIVE
*************************************************/
@media all and (min-width: 450px) {
  .lang-button {
    clear: none;
    display: none; }
  #language {
    display: block;
    float: right;
    padding-top: .5rem; } }

@media all and (min-width: 650px) {
  #language {
    padding-top: .6rem; } }

@media all and (min-width: 1200px) {
  #language {
    padding-right: .3rem; } }

/************************************************
* headerSlider
*************************************************/
.headerSlider {
  position: relative;
  background-color: #f4f4f4;
  display: block; }
  .headerSlider img {
    display: block; }
  .headerSlider .box {
    display: block;
    position: absolute;
    bottom: 0;
    background-color: rgba(44, 44, 43, 0.07);
    width: 50%;
    right: 0;
    bottom: 13%;
    padding: 3rem 7% 3rem 6rem;
    text-decoration: none; }
  .headerSlider .headline {
    font-size: 3.4rem;
    line-height: 1.5;
    color: #dd1617;
    font-weight: 700;
    margin-bottom: 1.5rem; }
  .headerSlider .subheadline {
    font-size: 1.8rem;
    line-height: 1.5;
    color: #dd1617;
    margin-bottom: .5rem; }
  .headerSlider .text {
    font-size: 1.8rem;
    line-height: 1.5;
    color: #444343; }
  .headerSlider.owl-theme .owl-nav .owl-prev,
  .headerSlider.owl-theme .owl-nav .owl-next {
    font-size: 7rem;
    color: white;
    opacity: 0.5;
    transition: color 0.2s ease; }
  .headerSlider.owl-theme .owl-nav .owl-prev {
    left: 2rem; }
  .headerSlider.owl-theme .owl-nav .owl-next {
    right: 2rem; }

/************************************************
* RESPONSIVE
*************************************************/
@media (max-width: 1400px) {
  .headerSlider .box {
    padding: 3rem 2.5% 3rem 4rem; } }

@media (max-width: 1200px) {
  .headerSlider .headline {
    font-size: 2.8rem; }
  .headerSlider .subheadline {
    font-size: 2.4rem; }
  .headerSlider .text {
    font-size: 1.6rem; } }

@media (max-width: 1100px) {
  .headerSlider .box {
    padding: 2rem 2.5%; }
  .headerSlider .headline {
    font-size: 2.4rem; }
  .headerSlider .subheadline {
    font-size: 2rem; } }

@media (max-width: 1000px) {
  .headerSlider .headline {
    margin: 0 0 .5rem 0; }
  .headerSlider .box {
    padding: 2%; } }

@media (max-width: 900px) {
  .headerSlider .box {
    background-color: #f4f4f4;
    position: relative;
    width: 100%;
    padding: 4% 4% 7% 4%; } }

@media (max-width: 500px) {
  .headerSlider .box {
    padding: 6% 4% 12% 4%; }
  .headerSlider .headline {
    font-size: 2rem; }
  .headerSlider .subheadline {
    font-size: 1.8rem; } }

/************************************************
* Cookie Banner
*************************************************/
#privacyWrapper {
  padding: .1rem 0;
  background-color: rgba(255, 255, 255, 0.9); }

#privacyInner {
  max-width: 1850px;
  margin-left: auto;
  margin-right: auto; }
  #privacyInner a:not(#closePrivacy) {
    color: #dd1617;
    text-decoration: none;
    transition: all .2s ease-in-out; }
    @media (min-width: 1025px) {
      #privacyInner a:not(#closePrivacy):hover {
        color: #af1112; } }

#closePrivacy {
  background-color: #ccc;
  display: inline-block;
  padding: .5rem 1rem;
  margin-left: 1rem;
  cursor: pointer;
  color: black;
  text-decoration: none;
  transition: background-color .5s ease; }
  @media (min-width: 1025px) {
    #closePrivacy:hover {
      background-color: #dd1617;
      color: white; } }

@media (max-width: 1920px) {
  #privacyInner {
    width: 95%;
    margin-left: auto;
    margin-right: auto; } }

@media (max-width: 600px) {
  #privacyInner {
    width: 92%; } }

/************************************************
* CONTENT
*************************************************/
/************************************************
* General CONTENT Stylings
*************************************************/
body > div > main > div ul {
  padding-left: 0;
  margin: 0 0 1.8rem 0; }

body > div > main > div li {
  font-size: 1.8rem;
  list-style-type: none;
  padding-left: 3rem;
  position: relative; }

body > div > main > div li::before {
  content: '\f178';
  position: absolute;
  color: #dd1617;
  left: .2rem;
  top: .4rem;
  margin-bottom: 1rem; }

body > div > main > div li.phone::before {
  content: '\e801';
  margin: 0; }

body > div > main > div li.fax::before {
  content: '\e802';
  margin: 0; }

body > div > main > div li.mail::before {
  content: '\e800';
  margin: 0; }

body > div > main > div li.address::before {
  content: '\e808';
  margin: 0; }

body > div > main > div a {
  color: #dd1617;
  font-size: 1.8rem;
  text-decoration: none; }

/************************************************
* RESPONSIVE
*************************************************/
@media (max-width: 1024px) {
  body > div > div a:hover {
    text-decoration: none; } }

/************************************************
* BUTTON
*************************************************/
div.button,
a.button {
  display: inline-block;
  color: white;
  font-size: 1.6rem;
  background-color: #2c2c2b;
  padding: 1.5rem 7rem 1.5rem 2rem;
  border-radius: 1rem;
  margin-top: 3rem;
  position: relative;
  text-decoration: none;
  transition: all .2s ease-in;
  margin: 3rem 3rem 0 0; }
  @media (min-width: 1025px) {
    div.button:hover,
    a.button:hover {
      background-color: rgba(44, 44, 43, 0.8);
      text-decoration: none; } }
  div.button::before,
  a.button::before {
    content: '';
    width: 2px;
    height: 60%;
    background-color: #dd1617;
    position: absolute;
    right: 5rem;
    top: 50%;
    transform: translateY(-50%); }
  div.button::after,
  a.button::after {
    position: absolute;
    right: 1.5rem;
    top: 50%;
    transform: translateY(-50%);
    color: white; }
  div.button:last-child,
  a.button:last-child {
    margin-right: 0; }
  div.button.form::after,
  a.button.form::after {
    content: '\e807'; }
  div.button.application::after,
  a.button.application::after {
    content: '\f0c9'; }
  div.button.phone::after,
  a.button.phone::after {
    content: '\e801'; }
  div.button.download::after,
  a.button.download::after {
    content: '\e806'; }
  div.button.video::after,
  a.button.video::after {
    content: '\f16a';
    font-size: 2rem; }
  div.button.link::after,
  a.button.link::after {
    content: '\f14d'; }

.buttons a.videoButton {
  display: inline-block;
  font-size: 5rem;
  position: relative;
  text-decoration: none;
  transition: all .2s ease-in; }
  .buttons a.videoButton::after {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: '\f16a';
    color: white; }
  @media (min-width: 1025px) {
    .buttons a.videoButton:hover {
      text-decoration: none; }
      .buttons a.videoButton:hover::after {
        color: rgba(255, 255, 255, 0.7);
        transition: color .3s ease-in; } }

/************************************************
* RESPONSIVE
*************************************************/
@media (max-width: 600px) {
  div.button,
  a.button {
    margin-top: 2rem; } }

@media (min-width: 600px) {
  .buttons a.videoButton {
    font-size: 6rem; } }

@media (min-width: 800px) {
  .buttons a.videoButton {
    font-size: 7rem; } }

@media (min-width: 1500px) {
  .buttons a.videoButton {
    font-size: 8rem; } }

/************************************************
* HEADER Standard
*************************************************/
.header {
  position: relative;
  /* Videoheader */ }
  .header img {
    display: block; }
  .header .headertext {
    /*position: absolute;
            bottom: 0;
            right: 0;
            left: 0;*/
    background-color: white;
    padding: 3.5rem 0; }
    .header .headertext .text {
      width: 70%; }
    .header .headertext h1,
    .header .headertext h2 {
      margin: 0 0 .5rem 0; }
    .header .headertext p {
      font-size: 2rem;
      color: #444343; }
  .header.video picture {
    position: relative;
    display: block; }
    .header.video picture .buttons {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
    .header.video picture .button {
      margin: 0; }

/************************************************
* RESPONSIVE
*************************************************/
@media (max-width: 1400px) {
  .header .headertext {
    padding: 2.5rem 0; } }

@media (max-width: 1300px) {
  .header .headertext {
    position: relative;
    padding: 2.5rem; }
    .header .headertext .wrap {
      width: 100%; } }

@media (max-width: 900px) {
  .header .headertext .text {
    width: 100%; } }

@media (max-width: 800px) {
  .header .headertext {
    padding: 2rem; } }

@media (max-width: 600px) {
  .header .headertext {
    padding-bottom: 2rem; }
    .header .headertext .text span {
      font-size: 1.8rem; } }

/************************************************
* HEADERHIGHLIGHT
*************************************************/
.headerHighlight {
  background-color: #e9e9e9;
  padding: 6rem 0; }
  .headerHighlight .wrap {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .headerHighlight .wrap::after {
      display: none; }
  .headerHighlight .image {
    width: 48%;
    text-align: center; }
  .headerHighlight .text {
    width: 48%; }
    .headerHighlight .text h1 {
      margin-top: 0; }
    .headerHighlight .text h2 {
      margin: 0; }
    .headerHighlight .text p:first-of-type {
      margin-bottom: 4rem; }
    .headerHighlight .text a.button {
      margin: 4rem 2rem 0 0;
      display: inline-block; }

/************************************************
* RESPONSIVE
*************************************************/
@media (max-width: 1200px) {
  .headerHighlight {
    padding: 6rem 0 4rem 0; }
    .headerHighlight .text .headline {
      font-size: 2.8rem; }
    .headerHighlight .image img {
      width: 80%; } }

@media (max-width: 900px) {
  .headerHighlight .wrap {
    flex-direction: column; }
  .headerHighlight .image {
    width: 100%; }
    .headerHighlight .image img {
      width: 55%; }
  .headerHighlight .text {
    width: 100%;
    margin-top: 2rem; }
    .headerHighlight .text p {
      margin-bottom: 1rem; } }

@media (max-width: 600px) {
  .headerHighlight {
    padding: 5rem 0 3rem 0; }
    .headerHighlight .text .headline {
      font-size: 2.6rem; }
    .headerHighlight .text a.button {
      margin: 2rem 1.5rem 0 0; } }

@media (max-width: 500px) {
  .headerHighlight .image img {
    width: 65%; }
  .headerHighlight p {
    margin-bottom: 0; } }

@media (max-width: 400px) {
  .headerHighlight .image img {
    width: 85%; }
  .headerHighlight .text .headline {
    font-size: 2.4rem; } }

/************************************************
* TEXTBOX
*************************************************/
.textBox {
  display: block;
  overflow: auto;
  padding: 6rem 0;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #f6f6f6;
  /* column settings */
  /* textBox with icons (häkchen) */
  /* only on newsdetail */ }
  .textBox.background::after {
    content: '';
    background-color: rgba(246, 246, 246, 0.9);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1; }
  .textBox.oneCol > h1,
  .textBox.oneCol > h2,
  .textBox.oneCol > h3,
  .textBox.oneCol .column {
    width: 60%; }
  .textBox.threeCol .column {
    width: 30%; }
  .textBox h1 {
    margin: 0 0 2rem 0;
    position: relative;
    z-index: 20; }
  .textBox h2 {
    margin: 0 0 1rem 0;
    position: relative;
    z-index: 20; }
  .textBox .columns {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    z-index: 20; }
  .textBox .column h2, .textBox .column h3 {
    color: #dd1617;
    margin: 2rem 0 1rem 0; }
  .textBox .column p {
    margin-top: 1.5rem; }
  .textBox .column img {
    margin-top: 2rem; }
  .textBox.threeCol.icons .column {
    padding-left: 3rem; }
  .textBox.threeCol.icons .column h2,
  .textBox.threeCol.icons .column h3 {
    position: relative; }
  .textBox.threeCol.icons .column h2::before,
  .textBox.threeCol.icons .column h3::before {
    content: '\e805';
    position: absolute; }
  .textBox.threeCol.icons .column h2::before {
    left: -3.5rem;
    top: .2rem;
    font-size: 2.7rem; }
  .textBox.threeCol.icons .column h3::before {
    left: -3rem;
    font-size: 1.9rem; }
  .textBox time {
    display: block;
    font-size: 1.8rem;
    font-weight: 700;
    color: #444343; }
  .textBox .linkText {
    display: block;
    margin-top: 5rem; }

/************************************************
* RESPONSIVE
*************************************************/
@media (max-width: 1200px) {
  .textBox {
    padding: 4rem 0; } }

@media (max-width: 1100px) {
  .textBox.oneCol > h1,
  .textBox.oneCol > h2,
  .textBox.oneCol > h3,
  .textBox.oneCol .column {
    width: 80%; } }

@media (max-width: 900px) {
  .textBox.threeCol .column {
    width: 48%; }
  .textBox.oneCol > h1,
  .textBox.oneCol > h2,
  .textBox.oneCol > h3,
  .textBox.oneCol .column {
    width: 100%; } }

@media (max-width: 650px) {
  .textBox.threeCol .column {
    width: 100%; }
  .textBox .column h3 {
    margin-top: 1rem; } }

@media (max-width: 600px) {
  .textBox {
    padding: 2rem 0; } }
