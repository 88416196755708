/************************************************
* Cookie Banner
*************************************************/

    #privacyWrapper {
        padding: .1rem 0;
        background-color: rgba(white, 0.9);
    }

    #privacyInner{
        max-width: 1850px;
        margin-left: auto;
        margin-right: auto;

        a:not(#closePrivacy) {
            color: $brandFirst;
            text-decoration: none;
            transition: all .2s ease-in-out;

            @media(min-width: 1025px) {
                &:hover {
                   color: darken($brandFirst, 10%);
                }
            }
        }
    }

    #closePrivacy {
        background-color: #ccc;
        display: inline-block;
        padding: .5rem 1rem;
        margin-left: 1rem;
        cursor: pointer;
        color: black;
        text-decoration: none;
        transition: background-color .5s ease;

        @media (min-width: 1025px) {
            &:hover {
                background-color: $brandFirst;
                color: white;
            }
        }
    }

    @media (max-width: 1920px) {
        #privacyInner{
            width: 95%;
            margin-left: auto;
            margin-right: auto;
        }
    }

    @media (max-width: 600px) {
        #privacyInner{
            width: 92%;
        }
    }