/************************************************
* BUTTON
*************************************************/  

    div.button,
    a.button {
        display: inline-block;
        color: white;
        font-size: 1.6rem;
        background-color: $brandThird;
        padding: 1.5rem 7rem 1.5rem 2rem;
        border-radius: 1rem;
        margin-top: 3rem;
        position: relative;
        text-decoration: none;
        transition: all .2s ease-in;
        margin: 3rem 3rem 0 0;

        @media (min-width: 1025px) {
            &:hover {
                background-color: rgba($brandThird, .8);
                text-decoration: none;
            }
        }

        &::before {
            content: '';
            width: 2px;
            height: 60%;
            background-color: $brandFirst;
            position: absolute;
            right: 5rem;
            top: 50%;
            transform: translateY(-50%);
        }

        &::after {
            @extend %icons;
            position: absolute;
            right: 1.5rem;
            top: 50%;
            transform: translateY(-50%);
            color: white; 
        }

        &:last-child {
            margin-right: 0;
        }

        &.form::after {
            content: '\e807';
        }

        &.application::after {
            content: '\f0c9';
        }

        &.phone::after {
            content: '\e801';
        }

        &.download::after {
            content: '\e806';
        }

        &.video::after {
            content: '\f16a';
            font-size: 2rem;
        }

        &.link::after {
            content: '\f14d';
        }
    }

    .buttons a.videoButton {
        display: inline-block;
        font-size: 5rem;
        position: relative;
        text-decoration: none;
        transition: all .2s ease-in;

        &::after {
            @extend %icons;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            content: '\f16a';
            color: white; 
        }

        @media(min-width: 1025px) {
            &:hover {
                text-decoration: none;

                &::after {
                    color: rgba(255, 255, 255, .7);
                    transition: color .3s ease-in;
                }
            }
        }
    }


/************************************************
* RESPONSIVE
*************************************************/

    @media (max-width: 600px) {
        div.button,
        a.button {
            margin-top: 2rem;
        }
    }

    @media(min-width: 600px) {
        .buttons a.videoButton {
            font-size: 6rem;
        }
    }

    @media(min-width: 800px) {
        .buttons a.videoButton {
            font-size: 7rem;
        }
    }

    @media(min-width: 1500px) {
        .buttons a.videoButton {
            font-size: 8rem;
        }
    }