/**********************************
* Flexnav Base Stylings
**********************************/

ul.flexnav {
    list-style-type: none;
    margin: 0 auto;
    padding: 0;
    transition: none;
    -webkit-transform-style: preserve-3d; // stop webkit flicker
    max-height: 0;
    overflow: hidden;

    &.opacity {
        opacity: 0;
    }

    &.flexnav-show {
        max-height: 2000px;
        opacity: 1;
        transition: all .5s ease-in-out;
    }

    /* generelle Stylings für alle Ebenen */

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    li{
        a {
            display: block;
            text-decoration: none;
        }
    }
}


/************************************************
* RESPONSIVE
*************************************************/

@media all and (min-width: 1200px) {

    ul.flexnav {
        position: relative;
        overflow: visible;
        max-height: none;
        z-index: 2;
        @include clearfix;

        &.opacity {
            opacity: 1;
        }

        li {

        }
    }

    /* Stylings erste Ebene (nicht vererbt) */

    ul.flexnav {
         > li {
            position: relative;
            float: left;
            overflow: visible;

            > a {

            }
        }
    }

    /* Stylings zweite Ebene (nicht vererbt) */

    ul.level2 {
        position: absolute; 
        top: auto;
        left: 0;
        display: none;
        
        > li {
            position: relative;
            > a {
                white-space: nowrap;
            }
        }
    }

    /* Stylings dritte Ebene (nicht vererbt) */

    ul.level3 {
        position: absolute; 
        top: 0;
        left: 100%;
        display: none;
        
        > li {
            position: relative;
            a {
                white-space: nowrap;
            }
        }
    }

    .menu-button {
        display: none;
    }
}