/************************************************
* HEADERHIGHLIGHT
*************************************************/

    .headerHighlight {
        background-color: $brandSecond;
        padding: 6rem 0;

        .wrap {
            display: flex;
            justify-content: space-between;
            align-items: center;
            
            &::after {
                display: none;
            }
        }

        .image {
            width: 48%;
            text-align: center;
        }

        .text {
            width: 48%;

            h1 {
                margin-top: 0;
            }

            h2 {
                margin: 0;
            }

            p:first-of-type {
                margin-bottom: 4rem;
            }

            a.button {
                margin: 4rem 2rem 0 0;
                display: inline-block;
            }
        }
    }
    

/************************************************
* RESPONSIVE
*************************************************/

    @media (max-width: 1200px) {
        .headerHighlight {
            padding: 6rem 0 4rem 0;
            .text .headline {
                font-size: 2.8rem;
            }

            .image img {
                width: 80%;
            }           
        }
    }

    @media (max-width: 900px) {
        .headerHighlight {
            .wrap {
                flex-direction: column;
            }

            .image {
                width: 100%;
                img {
                    width: 55%;
                }
            }

            .text {
                width: 100%;
                margin-top: 2rem;
                p {
                    margin-bottom: 1rem;
                }
            }
        }
    }

    @media (max-width: 600px) {
        .headerHighlight {
            padding: 5rem 0 3rem 0;

            .text {
                .headline {
                    font-size: 2.6rem;
                }

                a.button {
                    margin: 2rem 1.5rem 0 0;    
                }
            }
        }
    }

    @media (max-width: 500px) {
        .headerHighlight {
            .image img {
                width: 65%;
            }

            p {
                margin-bottom: 0;
            }
        }
    }

    @media (max-width: 400px) {
        .headerHighlight {
            .image img {
                width: 85%;
            }

            .text .headline {
                font-size: 2.4rem;
            }
        }
    }