/************************************************
* headerSlider
*************************************************/

    .headerSlider {
        position: relative;
        background-color: #f4f4f4;
        display: block;

        img {
            display: block;
        }

        .box {
            display: block;
            position: absolute;
            bottom: 0;
            background-color: rgba($brandThird, .07);
            width: 50%;
            right: 0;
            bottom: 13%;
            padding: 3rem 7% 3rem 6rem;
            text-decoration: none;
        }

        .headline {
            font-size: 3.4rem;
            line-height: 1.5;
            color: $brandFirst;
            font-weight: 700;
            margin-bottom: 1.5rem;
        }

        .subheadline {
            font-size: 1.8rem;
            line-height: 1.5;
            color: $brandFirst;
            margin-bottom: .5rem;
        }

        .text {
            font-size: 1.8rem;
            line-height: 1.5;
            color: $fontColor;
        }

        &.owl-theme .owl-nav {
            .owl-prev,
            .owl-next {
                font-size: 7rem;
                color: white;
                opacity: 0.5;
                transition: color 0.2s ease;
            }

            .owl-prev {
                left: 2rem;
            }

            .owl-next {
                right: 2rem;
            }
        }
    }

/************************************************
* RESPONSIVE
*************************************************/

    @media (max-width: 1400px) {
        .headerSlider .box {
            padding: 3rem 2.5% 3rem 4rem;
        }
    }

    @media (max-width: 1200px) {

        .headerSlider {

            .headline {
              font-size: 2.8rem;
            }

            .subheadline {
              font-size: 2.4rem;
            }

            .text {
                font-size: 1.6rem;
            }

        }

    }

    @media (max-width: 1100px) {
        .headerSlider {
            .box {
                padding: 2rem 2.5%;
            }

            .headline {
              font-size: 2.4rem;
            }

            .subheadline {
              font-size: 2rem;
            }
        }
    }

    @media (max-width: 1000px) {
        .headerSlider .headline {
            margin: 0 0 .5rem 0;
        }

        .headerSlider .box {
            padding: 2%;
        }
    }

    @media (max-width: 900px) {
        .headerSlider {
            .box {
                background-color: #f4f4f4;
                position: relative;
                width: 100%;
                padding: 4% 4% 7% 4%;
            }
        }
    }

    @media (max-width: 500px) {
        .headerSlider {
            .box {
                padding: 6% 4% 12% 4%;
            }

            .headline {
              font-size: 2rem;
            }

            .subheadline {
              font-size: 1.8rem;
            }
        }
    }